<template>
  <UCard
    v-if="settingsPack.id"
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto h-full scroll-stable ',
        padding: 'sm:pt-4 pt-4'
      },
      footer: {
        base: 'sticky bottom-0 left-0 right-0 flex justify-end',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        hash="preview"
        :allow-go-back="allowGoBack"
        :title="`Preview: ${settingsPack.name}`"
        data-test="settings-pack-header"
      />
    </template>
    <div>
      <div class="flex flex-col">
        <p class="text-sm font-bold">Task types & Custom fields</p>
        <div class="h-full min-h-1 overflow-y-auto mt-3">
          <TaskTypeSettingsItem
            v-for="taskType in settingsPack.taskTypes.filter(
              (taskType) => taskType.level === TaskLevel.MODULE
            )"
            :key="taskType.id"
            class="px-0"
            :task-type="taskType"
          />
          <UDivider
            v-if="
              settingsPack.taskTypes.some(
                (taskType) => taskType.level === TaskLevel.TASK
              )
            "
            class="my-1.5"
          />
          <TaskTypeSettingsItem
            v-for="taskType in settingsPack.taskTypes.filter(
              (taskType) => taskType.level === TaskLevel.TASK
            )"
            :key="taskType.id"
            class="px-0"
            :task-type="taskType"
          />
          <UDivider class="my-1.5" />
          <TaskTypeSettingsItem
            v-for="taskType in settingsPack.taskTypes.filter(
              (taskType) => taskType.level === TaskLevel.SUBTASK
            )"
            :key="taskType.id"
            class="px-0"
            :task-type="taskType"
          />
        </div>
      </div>
      <div v-if="settingsPack.statuses.length" class="mt-6">
        <p class="text-sm font-bold">Status</p>
        <div class="mt-3 space-y-4">
          <StatusSettingsType
            v-for="statusType in STATUS_TYPE_CHOICES"
            :key="statusType.value"
            :status-type="statusType"
            :parent-id="settingsPack.id"
            :statuses="settingsPack.statuses"
            :preview="true"
            source="settings-pack"
          />
        </div>
      </div>
      <div v-if="settingsPack.labels.length" class="mt-6">
        <p class="text-sm font-bold">Labels</p>
        <div class="mt-3 space-y-1">
          <div
            v-for="label in settingsPack.labels"
            :key="label.id"
            class="flex items-center py-1 px-2 rounded-md"
            :style="getColorPreset(label.color)"
          >
            <Tooltip :text="label.name">
              <template #default="{ getTextRef }">
                <div
                  :ref="getTextRef"
                  class="text-sm line-clamp-1 break-all w-full text-start font-medium"
                >
                  {{ label.name }}
                </div>
              </template>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <UButton
        color="gray"
        size="sm"
        data-test="settings-pack-edit-button"
        @click="openSettingsPackModifyTab(settingsPack.id)"
      >
        Edit
      </UButton>
      <UButton
        v-if="isBoardInSettingsPack"
        color="red"
        size="sm"
        class="ml-2"
        @click="unAssignModalVisible = true"
      >
        Remove from this board
      </UButton>
      <template v-else>
        <UButton
          v-if="source === 'board'"
          color="green"
          size="sm"
          class="ml-2"
          @click="onApplyToBoard(settingsPack.id)"
        >
          Apply to current board
        </UButton>
        <UButton
          v-else
          color="green"
          size="sm"
          class="ml-2"
          @click="assignModalVisible = true"
        >
          Assign to board
        </UButton>
      </template>
    </template>
  </UCard>
  <SettingsPackAssignModal
    v-model:visible="assignModalVisible"
    :settings-pack="settingsPack"
    @close="assignModalVisible = false"
    @updated="assignModalVisible = false"
  />
  <SettingsPackUnassignModal
    v-model:visible="unAssignModalVisible"
    :settings-pack="settingsPack"
    :board-id="parentId"
    @close="unAssignModalVisible = false"
    @updated="unAssignModalVisible = false"
  />
</template>

<script lang="ts" setup>
import { TaskLevel } from '#task/constant'
import { STATUS_TYPE_CHOICES } from '#status/constant'

const props = defineProps({
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
})

const { setSettingTab } = useBoardSettingsNavigator()
const { currentBoard, currentSettingsPack, currentWorkspace } =
  useWorkspaceSharedState()
const { toggleAssignSettingsPack } = useToggleSettingsPack()

const assignModalVisible = ref(false)
const unAssignModalVisible = ref(false)

const allowGoBack = computed(() => props.source === 'board')
const settingsPack = computed(() => currentSettingsPack?.value)
const isBoardInSettingsPack = computed(() => {
  return settingsPack.value?.boards?.some(
    (board) => board.id === currentBoard.value.id
  )
})

const onApplyToBoard = async (settingsPackId: string) => {
  await toggleAssignSettingsPack(settingsPackId, [props.parentId])
}

const openSettingsPackModifyTab = (settingsPackId: string) => {
  const baseUrl = `/w/${currentWorkspace.value.handle}/settings-packs`
  if (currentBoard.value.id) {
    return navigateTo(
      `${baseUrl}?id=${settingsPackId}#settings-pack`,
      { open: { target: '_blank' } }
    )
  }

  return setSettingTab('settingsPack')
}
</script>
